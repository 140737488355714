import React from 'react';

import { Card, Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import searchResultsRedux from '../../../redux/searchResult';

import './ResultsTable.scss';

const BulkSearchCard = ({ SearchResults, id }) => {
  const dispatch = useDispatch();

  const onRecommendedSearch = gtin => {
    dispatch(searchResultsRedux.thunks.createSingleSearchResult(gtin)).then(() => {});
  };

  const description = element => {
    const { error_message, product_description } = element || {};
    if (product_description && product_description[Object.keys(product_description)[0]]) {
      return product_description[Object.keys(product_description)[0]];
    } else if (!!error_message?.validationErrors?.match(/<.*>/)) {
      const gtin = error_message?.validationErrors?.replace(/<.*/, '');
      const gtins = error_message?.validationErrors?.match(/<.*>/)[0].replace(/<|>/g, '');
      return (
        <div>
          {gtin} <a onClick={() => onRecommendedSearch(gtins)}>{gtins}</a> ?
        </div>
      );
    }
  };

  return (
    <div className="cards-list-margin">
      <Row className="list-margin-bottom" gutter={16}>
        {SearchResults?.map((element, index) => (
          <Col span={24} className="card-styling" key={index}>
            <Card title="" className={!element.error_message.validationStatus && 'error'}>
              <Row className="row-alignment">
                <div className="title">GTIN: </div>
                <div className="content">{element.gtin}</div>
              </Row>
              <Row className="row-alignment">
                <div className="title">Description: </div>
                <div className="content">{description(element)}</div>
              </Row>
              <Row>
                {element.error_message.validationStatus && (
                  <Link
                    to={`/search_results/${id}/single/search_responses/${element.id}/${element.type}`}
                    className="content no-margin"
                  >
                    Show
                  </Link>
                )}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BulkSearchCard;
