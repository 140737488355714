import React, { useEffect, useState } from 'react';
import { Form, Input, Row } from 'antd';
import Carousel from '../../../components/Common/Carousel';
import './Single.scss';
import Message from '../../Common/Message/Message';
import CompleteMessage from '../../Common/CompleteMessage/CompleteMessage';
import Loadable from '../../../components/Common/Loadable';

import GCPView from './GCPView';
import GTINView from './GTINView';
import CompanyView from "./CompanyView"
import { useDispatch, useSelector } from 'react-redux';
import searchResultsRedux from '../../../redux/searchResult';
import BreadCrumbs from '../../Common/BreadCrumbs/BreadCrumbs';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Tabs from '../../Common/Tabs/Tabs';
import axios from '../../../utils/requestHelpers';

const Single = ({ searchResponse, searchResultId }) => {
  const { gtin, product_description, company_name, type, status, error_message, id } = searchResponse || {};
  const dispatch = useDispatch();
  const [newGtin, setNewGtin] = useState(gtin);
  const searchResultsErrors = useSelector(searchResultsRedux.selectors.getErrors);
  const currentSearchResult = useSelector(searchResultsRedux.selectors.getCurrentSearchResult);
  const [flag, setFlag] = useState(false);
  const [resultsError, setResultsError] = useState();
  const [printLoading, SetPrintLoading] = useState(false);

  useEffect(() => {
    flag &&
      dispatch(searchResultsRedux.thunks.createSingleSearchResult(newGtin)).then(() => {
        setFlag(false);
      });
  }, [dispatch, flag]);

  useEffect(() => {
    if (!!searchResultsErrors[0]?.match(/<.*>/)) {
      const gtin = searchResultsErrors[0]?.replace(/<.*/, '');
      const gtins = searchResultsErrors[0]?.match(/<.*>/)[0].replace(/<|>/g, '');
      setResultsError(
        <div>
          {gtin} <a onClick={() => onRecommendedSearch(gtins)}>{gtins}</a> ?
        </div>,
      );
    } else {
      setResultsError(searchResultsErrors);
    }
  }, [searchResultsErrors]);

  const onRecommendedSearch = gtin => {
    setNewGtin(gtin);
    setFlag(true);
  };

  const PrintResult = async () => {
    SetPrintLoading(true)
    const url = `v1/users/search_results/${searchResultId}/search_responses/${searchResponse.id}/download_pdf.pdf${type === 'gcp' ? '?type=gcp' : ''}`
    const response = await axios.get(url, {
      responseType: 'blob',
    });
    setTimeout(() => {
      SetPrintLoading(false)
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'download_pdf.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }, 500)
  }

  useEffect(() => {
    setNewGtin(gtin);
  }, [gtin]);

  const handleSearch = () => {
    dispatch(searchResultsRedux.thunks.createSingleSearchResult(newGtin));
  };

  const searchButton = (
    <div className="search-button" onClick={handleSearch}>
      Search
    </div>
  );

  const handleChangeNewGtin = e => {
    setNewGtin(e.target.value);
  };

  const renderSearchResultError = () =>
    currentSearchResult?.error_message && (
      <Message
        data={
          <div>
            <span>{currentSearchResult?.error_message}</span>
          </div>
        }
        contactAdmin
        className="warning"
      />
    );
  const renderMessageSuccess = () => {
    if (status === 'complete') {
      return (
        <CompleteMessage
          type="complete"
          data="complete Data"
          className="complete"
          company_name={company_name?.[Object.keys(company_name)?.[0]]}
        />
      );
    } else if (['success', 'incomplete'].includes(status)) {
      return (
        <Message
          data={
            <>
              <span>This number is registered to company: </span>
              <span className="warning-style">{company_name?.en || 'unknown'}</span>
              <span>, and some data is available.</span>
            </>
          }
          className="warning"
        />
      );
    }
    return <Message data={error_message?.validationErrors} className="error" />;
  };

  const renderMessageError = () => <Message data={resultsError} className="error" />;
  const renderMessage = () => {
    if (searchResultsErrors) {
      return renderMessageError();
    } else return renderMessageSuccess();
  };
  const BreadcrumbsValues = [
    {
      href: '/search_results',
      value: 'Search Results',
    },
    {
      href: `/search_results/${currentSearchResult?.id}/search_responses/${currentSearchResult?.query_type}`,
      value: currentSearchResult?.id,
    },
    {
      href: `/search_results/${currentSearchResult?.id}/search_responses/${currentSearchResult?.query_type}`,
      value: 'Search Response',
    },
    {
      href: '',
      value: id,
    },
  ];

  const ExportButtons = () => <div className='export-buttons-wrapper'>
    <div className="export-buttons">
      <button onClick={PrintResult}>Print</button>
    </div>
  </div>

  const ProductInformation = () => <>
    <div className="product-container">
      <div className="card-title">
        {type === 'gcp' ? 'Global Company Prefix' : product_description?.[Object.keys(product_description)?.[0]]}
      </div>
      <Row>
        <Carousel images={searchResponse?.product_image} />
        {type === 'gcp' ? (
          <GCPView searchResponse={searchResponse} />
        ) : (
          <GTINView searchResponse={searchResponse} />
        )}
      </Row>
    </div>
    {ExportButtons()}
  </>

  const CompanyInformation = () => <>
    <div className="product-container">
      <div className="card-title">
        Information about the company that licenced this GTIN
      </div>
      <Row>
        <CompanyView searchResponse={searchResponse} />
      </Row>
    </div>
    {ExportButtons()}
  </>

  const loadTabs = () => [
    {
      title: "Product Information",
      component: type === 'gtin' ? <ProductInformation /> : null
    },
    {
      title: "Company Information",
      component: <CompanyInformation />
    }
  ]

  const onExampleSearch = () => {
    setFlag(true);
    setNewGtin(6222014336140);
  };
  return (
    <Loadable spinning={printLoading}>
      <div className="single-padding">
        <BreadCrumbs singleBreadcrumbClassName="single-breadcrumb-style" values={BreadcrumbsValues} />
        <div class="search-results">Enter a barcode number</div>
        <Form.Item className="formItem ant-form-item">
          <Input
            prefix={<SearchOutlined />}
            addonAfter={searchButton}
            onPressEnter={handleSearch}
            placeholder="Search"
            value={newGtin}
            onChange={handleChangeNewGtin}
          />
        </Form.Item>
        <div className="example-search">
          Example Search: <a onClick={onExampleSearch}>6222014336140</a>
        </div>
        {renderSearchResultError()}
        {renderMessage()}
        {!searchResultsErrors && status !== 'not_found' && (
          <Tabs tabs={loadTabs()}>
          </Tabs>
        )}
      </div>
    </Loadable>
  );
};

export default Single;
