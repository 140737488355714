import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import './Tabs.scss';

const Tabs = ({ tabs }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const [data, SetData] = useState([]);

    useEffect(() => {
      SetData(tabs.filter(tab => tab && tab.component))
    }, [tabs])
    

  return (
    <div className='tabs-wrapper'>
        <div className='tabs-header'>
            {data.length && data
            .map((tab, index) => {
                return (<div onClick={() => {setCurrentTabIndex(index)}} className={`tabs-item ${index === currentTabIndex? 'tabs-item-active' : ''}`}>{tab.title}</div>)
            })}
        </div>
        {data.length && <div className='tabs-body'>
            {data[currentTabIndex || 0].component}
        </div>}
    </div>
  );
};

export default Tabs;
