import React, { useEffect } from 'react';
import useQueryParams from '../../hooks/useQueryParams';
import './SearchResponses.scss';
import Single from '../../components/SearchResponse/Single';
import Bulk from '../../components/SearchResponse/Bulk';
import { useDispatch, useSelector } from 'react-redux';
import searchResultsRedux from '../../redux/searchResult';
import { useLocation } from 'react-router-dom';
import Loadable from '../../components/Common/Loadable';

const SearchResponses = () => {
  const { params } = useQueryParams();
  const type = params.get('type');
  const id = params.get('id');
  const searchResponseType = params.get('searchResponseType');
  const searchResponseId = params.get('searchResponseId');
  const searchResultsLoading = useSelector(searchResultsRedux.selectors.getLoadingState);
  const currentSearchResponse = useSelector(searchResultsRedux.selectors.getCurrentSearchResponse);
  const currentSearchResult = useSelector(searchResultsRedux.selectors.getCurrentSearchResult);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (searchResponseId) {
      dispatch(
        searchResultsRedux.thunks.fetchSearchResponse({
          id,
          searchResponseId,
          type: searchResponseType,
        }),
      );
    } else {
      dispatch(searchResultsRedux.thunks.fetchSearchResult(id));
    }
  }, [pathname]);

  return (
    <div className="search-responses-styling">
      <Loadable spinning={searchResultsLoading}>
        <div>
          {type === 'single' || searchResponseId ? (
            <Single
              searchResultId={currentSearchResult.id}
              searchResponse={searchResponseId ? currentSearchResponse : {...currentSearchResult.search_responses?.[0],gtin:currentSearchResult.search_responses?.[0]?.gtin || currentSearchResult?.query}}
            />
          ) : (
            <Bulk />
          )}
        </div>
      </Loadable>
    </div>
  );
};
export default SearchResponses;
