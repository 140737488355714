import React from 'react';

const productLabelsAndNames = [
  { label: 'Company Name', name: 'company_name', parseObject: true, required: true },
  { label: "Licence Key", name: "licence_key", parseString: true, required: true },
  { label: "Licence Type", name: "licence_type", parseString: true, required: true },
  { label: "Licence Status", name: "licence_status", parseString: true, required: false },
  { label: "Licensee Name", name: "mo_name", parseString: true, required: true },
  { label: "Licensee GLN", name: "licensee_gln", parseString: true, required: true },
  { label: "Address Language", name: "address_language", parseObject: true, required: false },
  { label: "Street Address", name: "street_address", parseObject: true, required: false },
  { label: "Street Address Line 2", name: "street_address_line_2", parseObject: true, required: false },
  { label: "Street Address Line 3", name: "street_address_line_3", parseObject: true, required: false },
  { label: "Street Address Line 4", name: "street_address_line_4", parseObject: true, required: false },
  { label: "Cross Street", name: "cross_street", parseObject: true, required: false },
  { label: "Address Suburb", name: "address_suburb", parseObject: true, required: false },
  { label: "Address Locality", name: "address_locality", parseObject: true, required: false },
  { label: "Address Region", name: "address_region", parseObject: true, required: false },
  { label: "Postal Name", name: "postal_name", parseObject: true, required: false },
  { label: "Country Code", name: "country_code", parseString: true, required: false },
  { label: "Country Subdivision Code", name: "country_subdivision_code", parseString: true, required: false },
  { label: "Postal Code", name: "postal_code", parseString: true, required: false },
  { label: "Post Office Box Number", name: "post_office_box_number", parseString: true, required: false },
  { label: "Email", name: "emails", parseArray: true, required: false },
  { label: "Telephone", name: "telephones", parseArray: true, required: false },
  { label: "Website", name: "websites", parseArray: true, required: false },
]

const CompanyView = ({ searchResponse }) => {

  const checkRecordValue = (value) => {
    return value && Object.keys(value).length
  }

  const renderObject = (data) => {
    return Object.keys(data)?.map(value => {
      return <> {`(${value})  ` + data[value]} <br /></>;
    });
  }

  const renderArray = (data) => {
    return data?.map(value => {
      return <> {`${value}  `} <br /></>;
    });
  }

  const renderTableDataRow = (element) => searchResponse && (element.required || checkRecordValue(searchResponse[element.name])) ? <tr>
    <td>{element.label}</td>
    {
      !checkRecordValue(searchResponse[element.name])? <td></td> : 
      element.parseObject ? <td>{renderObject(searchResponse[element.name])}</td> :
      element.parseArray ? <td>{renderArray(searchResponse[element.name])}</td> :
      element.parseString ? <td>{searchResponse[element.name]}</td> : <td></td>
    }
  </tr> : <></>

  const renderTableData = ({ data }) =>
    data.map((element, index) => (
      <div key={index} className="table-style">
        {renderTableDataRow(element)}
      </div>
    ));

  return (
    <div className="gtin-table-style company-table-style">
      <table>
        <tbody>
          {renderTableData({
            data: productLabelsAndNames,
          })}
        </tbody>
      </table>

    </div>
  );
};

export default CompanyView;
